import { useEffect } from 'react';
import { useRouter } from 'next/router';



export default function Custom404() {
  const router = useRouter();

  useEffect(() => {
    
    router.replace('/');  // Using 'replace' to prevent back navigation to the 404 page
  }, [router]);

  return (
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <h1>404 - Page Not Found</h1>
      <p>Redirecting you to the homepage...</p>
    </div>
  );
}

